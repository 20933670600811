import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';
import { responsiveDwPictureFragment } from './ResponsiveDwPicture';
import { isInternalDwImageUrl, replaceFormatInUrl, format } from '../utils/imgUtils';
import { replaceImageFormatInPosterImageUrl } from './hooks/useLazyPosterImage';
import {
  isArticle, isLiveblog, isLiveblogPost, isValidList, isVideo,
} from '../utils/contentUtils';
import { makeMemoFragment } from '../utils/graphql';
import { extendJsonLdObject } from '../utils/jsonLd';
import { useFrontendConfig } from './FrontendConfigContext.jsx';

const jsonLdImageFormats = [format['80X'].formats[4], format['60X'].formats[4]];

export const jsonLdScriptFragment = makeMemoFragment({
  name: 'JsonLdScript',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on NamedAspect {
          title
        }
        ... on AssociationsAspect {
          mainContentImageLink {
            targetId
            target {
              ...${responsiveDwPictureFragment.name}
            }
          }
        }
        ... on Article {
          canonicalUrl
          persons {
            namedUrl
            fullName
          }
          firstPublicationDate
          lastPublicationDate
          lastModifiedDate
        }
        ... on Liveblog {
          canonicalUrl
          persons {
            namedUrl
            fullName
          }
          firstPublicationDate
          lastPublicationDate
        }
        ... on LiveblogElement {
          canonicalUrl
          persons {
            namedUrl
            fullName
          }
          firstPublicationDate
          lastPublicationDate
          lastModifiedDate
        }
        ... on Video {
          teaser
          hlsVideoSrc
          durationIso8601
          validUntilDate
          neverDelete
          posterImageLink {
            stillUrl
          }
          firstPublicationDate
          lastPublicationDate
          lastModifiedDate
        }
      }
      ${responsiveDwPictureFragment.fragment()}
    `;
  },
});

const getLdScriptAuthors = ({ persons }) => {
  if (!isValidList(persons)) {
    return [{
      '@type': 'Organization',
      name: 'Deutsche Welle',
      url: 'https://www.deutsche-welle.com',
    }];
  }
  return persons.map(person => ({
    '@type': 'Person',
    name: person.fullName,
    url: person.autoDelivery ? `https://www.deutsche-welle.com${person.namedUrl}` : undefined,
  }));
};

const getLdScriptImageUrlArray = (content, staticBaseHost) => {
  const mainImageStaticUrl = content?.mainContentImageLink?.target?.staticUrl;
  if (mainImageStaticUrl) {
    return jsonLdImageFormats.map(imgFormat => replaceFormatInUrl(mainImageStaticUrl, imgFormat));
  }

  const posterImageUrl = content?.posterImageUrl;
  if (posterImageUrl) {
    if (isInternalDwImageUrl({ imageUrl: posterImageUrl, staticBaseHost })) {
      return jsonLdImageFormats.map(imgFormat => replaceImageFormatInPosterImageUrl(posterImageUrl, imgFormat));
    }
    return [posterImageUrl];
  }
  return undefined;
};

const makeArticleJsonLd = content => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': content.canonicalUrl,
  },
  publisher: {
    '@type': 'Organization',
    name: 'Deutsche Welle',
    logo: {
      '@type': 'ImageObject',
      url: 'https://dw.com/images/icons/favicon-180x180.png',
      width: '180',
      height: '180',
    },
  },
  author: getLdScriptAuthors(content),
  headline: content.title,
  image: getLdScriptImageUrlArray(content),
  datePublished: content.firstPublicationDate || content.lastPublicationDate,
  dateModified: content.lastModifiedDate,
});

const makeLiveblogJsonLd = content => ({
  ...makeArticleJsonLd(content),
  dateModified: content.lastPublicationDate,
});

const makeLiveblogPostSharingJsonLd = content => ({
  ...makeArticleJsonLd(content),
  '@type': 'BlogPosting',
});

const makeVideoJsonLd = (content, staticBaseHost) => ({
  '@context': 'https://schema.org',
  '@type': 'VideoObject',
  name: content.title,
  description: content.teaser,
  thumbnailUrl: getLdScriptImageUrlArray(content, staticBaseHost),
  uploadDate: content.firstPublicationDate || content.lastPublicationDate,
  dateModified: content.lastModifiedDate,
  expires: content.neverDelete ? undefined : content.validUntilDate,
  duration: content.durationIso8601,
  contentUrl: content.hlsVideoSrc,
  embedUrl: undefined,
});

const getJsonLdObject = ({ content, staticBaseHost }) => {
  if (isArticle(content)) {
    return makeArticleJsonLd(content);
  }
  if (isLiveblog(content)) {
    return makeLiveblogJsonLd(content);
  }
  if (isLiveblogPost(content)) {
    return makeLiveblogPostSharingJsonLd(content);
  }
  if (isVideo(content)) {
    return makeVideoJsonLd(content, staticBaseHost);
  }
  return null;
};

export const JsonLdScript = ({ content }) => {
  const { staticBaseHost } = useFrontendConfig();
  const jsonLdObject = getJsonLdObject({ content, staticBaseHost });
  if (!jsonLdObject) {
    return null;
  }
  const extendedJsonLdObject = extendJsonLdObject(jsonLdObject);

  return (
    <Helmet>
      <script type="application/ld+json">
        { JSON.stringify(extendedJsonLdObject) }
      </script>
    </Helmet>
  );
};
